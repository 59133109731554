import React, { useState, useEffect, useContext } from "react";
import { Form, Input, Button } from "antd";
import { Spin } from "antd";
import { SendOutlined } from "@ant-design/icons";
import Message from "../Message/Message";
import takeMessages from "../../services/Message/TakeMessages";
import createMessage from "../../services/Message/CreateMessage";
import "./styles.css";
import Pusher from "pusher-js";
import notify from "../../services/Notification/Notification";
import { getCookie } from "typescript-cookie";
import NameContext from "../../contexts/NameContext";

const formatDate = (date: string): string => {
    const splitted = date.split("-");
    if (splitted.length === 1) {
        return date;
    } else {
        return `${splitted[2]}/${splitted[1]}/${splitted[0]}`;
    }
};

const messagesNumber = 8;

const MessageArea = () => {
    const [progress, setProgress] = useState(true);
    const [data, setData] = useState(Array(messagesNumber));
    const {name} = useContext(NameContext);

    useEffect(() => {
        let pusher = new Pusher("bde1144948f2aed318bc", {
            cluster: "sa1",
        });
        let channel = pusher.subscribe("sif-news-development");
        channel.bind("message", (message: any) => {
            notify(message.message);
            takeMessages(messagesNumber, (value: boolean) => {
                return true;
            }).then((response: any) => {
                setData(response.data);
            });
        });
        takeMessages(messagesNumber, (value: boolean) => {
            setProgress(value);
        }).then((response: any) => {
            setData(response.data);
        });
        setTimeout(() => {setProgress(false)}, 2000);
    }, []);

    return progress ? (
        <div className="messageLoadingSpin">
            <Spin size="large" />
        </div>
    ) : (
        <div className="messageArea">
            <Form
                onFinish={(values: any) => {
                    createMessage(values.message, name).catch((error) => {
                        alert('Atualize a página e tente novamente');
                    });
                }}>
                <Input.Group className="messageAreaInputGroup" compact>
                    <Form.Item name="message" style={{ width: "80%" }}>
                        <Input placeholder="Digite aqui sua mensagem..." />
                    </Form.Item>
                    <Button style={{ width: "20%", height: 32 }} htmlType="submit">
                        <SendOutlined />
                    </Button>
                </Input.Group>
            </Form>
            {data.map((value: any) => (
                <Message key={value.id} name={value.user_name} message={value.message} date={formatDate(value.created_at)} />
            ))}
            <div>
                <a href="messages" style={{fontSize: '1rem', color: "#06793a", textDecoration: "none"}}>
                    Ver todas...
                </a>
            </div>
        </div>
    );
};

export default MessageArea;
