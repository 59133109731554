import React from "react";
import axios from "axios";
import { setCookie, getCookie } from "typescript-cookie";
import URL from "../URLs.json";
import Main from "../../components/Main/Main";

type LoginResponse = {
    token: string;
};

const login = async (email: string, password: string, handleError: (value: string) => void, handleContent: (content: any) => void) => {
    const url = URL.production.auth.login;

    try {
        const { data } = await axios.post<LoginResponse>(url, {
            email: email,
            password: password,
        });

        setCookie("token", data.token, { expires: 1, secure: true });
        handleContent(<Main />);
    } catch {
        handleError("block");
    }
};

export default login;
