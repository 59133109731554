import React from "react";
import Router from "./Routes";
import { BrowserRouter } from "react-router-dom";
import NameProvider from "./contexts/NameProvider";

function App() {
    return (
        <NameProvider>
            <BrowserRouter>
                <Router />
            </BrowserRouter>
        </NameProvider>
    );
}

export default App;
