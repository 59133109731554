import axios from "axios";
import { getCookie } from "typescript-cookie";
import URL from "../URLs.json";

const me = async () => {
    const url = URL.production.auth.me;

    try {
        const { data } = await axios.post(url, null, {
            headers: {
                Authorization: "Bearer " + getCookie("token"),
            },
        });
        return data;
    } catch (err: any) {
        throw new Error(err);
    }
};

export default me;
