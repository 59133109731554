import axios from "axios";
import { getCookie } from "typescript-cookie";
import URL from "../URLs.json";

const createMessage = (message: string, user_name: string | undefined) => {
    let url = URL.production;
    console.log(user_name)
    return axios.post(
        url.news.default,
        {
            message: message,
            user_name: user_name,   
        },
        {
            headers: {
                Authorization: "Bearer " + getCookie("token"),
            },
        }
    );
};

export default createMessage;
