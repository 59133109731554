import axios from "axios";
import { getCookie } from "typescript-cookie";
import URL from "../URLs.json";

const getJournals = async () => {
    let url = URL.production;
    const {data} =  await axios.get(
        url.journals,
        {
            headers: {
                Authorization: "Bearer " + getCookie("token"),
            },
        }
    );

    return data;
};

export default getJournals;
