import React, { useState } from "react";
import { getCookie } from "typescript-cookie";
import NewsCalendar from "../../components/Calendar/Calendar";
import Container from "../../components/Container/Container";
import Header from "../../components/Header/Header";
import MessageArea from "../../components/MessageArea/MessageArea";
import titles from "../../styles/title.json";
import "./styles.css";

const Main = () => {
    const [content, setContent] = useState(<NewsCalendar />);
    const [title, setTitle] = useState(titles.calendar);

    return (
        <>
            <Header
                handlePageChange={(newContent: JSX.Element, newTitle: string) => {
                    setContent(newContent);
                    setTitle(newTitle);
                }}
            />
            <div className="index">
                <div className="contentContainer">
                    <div className="mainContent">
                        <Container title={title}>{content}</Container>
                    </div>
                    <div className="secondaryContent">
                        <Container title="Linha do tempo">
                            <MessageArea />
                        </Container>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Main;
