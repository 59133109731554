import React from "react";
import theme from "../../styles/theme";
import "./styles.css";

const Container = (props: any) => {
    return (
        <div className="container" style={{ backgroundColor: theme.secondary }}>
            <div className="containerHeader" style={{ backgroundColor: theme.primary }}>
                <h3>{props.title}</h3>
            </div>
            <div className="contentArea">{props.children}</div>
        </div>
    );
};

export default Container;
