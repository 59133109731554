import React from "react";
import { ClockCircleOutlined } from "@ant-design/icons";
import leaf from "../../img/leaf.png";
import "./styles.css";
import { Card } from "antd";

const Message = (props: any) => {
    return (
        <Card
            className="messageCard"
            title={props.name}
            bordered={false}
            extra={
                <div className="messageCardTime">
                    <ClockCircleOutlined />
                    {props.date}
                </div>
            }
            // actions={[
            //     <div>
            //         <img src={leaf} alt="" />
            //         <span className="likesNumber">718</span>
            //     </div>,
            // ]}
        >
            {props.message}
        </Card>
    );
};

export default Message;
