import React from "react";
import brand from "../../img/brand.png";

const showNotification = (message: string) => {
    new Notification("Nova mensagem na linha do tempo!", {
        icon: brand,
        body: message.substring(0, 30) + "...",
    });
};

const notify = (message: any) => {
    if (Notification.permission === "granted") {
        showNotification(message);
    } else {
        Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
                showNotification(message);
            }
        });
    }
};

export default notify;
