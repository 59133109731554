import axios from "axios";
import { getCookie, removeCookie } from "typescript-cookie";
import URL from "../URLs.json";

const logout = async () => {
    const url = URL.production.auth.logout;

    try {
        const res = await axios.post(url, null, {
            headers: {
                Authorization: "Bearer " + getCookie("token"),
            },
        });

        removeCookie("token");
        removeCookie("name");
        window.location.reload();
    } catch {}
};

export default logout;
