import axios from "axios";
import { getCookie } from "typescript-cookie";
import URL from "../URLs.json";

const getMessages = async () => {
    let url = URL.production;
    return axios.get(url.news.default, {
        headers: {
            Authorization: "Bearer" + getCookie("token"),
        },
    });
};

export default getMessages;
