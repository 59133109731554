import React from "react";
import "./styles.css";

const Tag = (props: any) => {
    return (
        <span className="tagArea" style={{ backgroundColor: props.color, color: props.color == '#FFFF44' ? 'black' : 'white' }}>
            {props.tag}
        </span>
    );
};

export default Tag;
