import React from "react";
import { useState } from "react";
import { Form, Input, Button, Spin } from "antd";
import { LockOutlined, UserOutlined, LoadingOutlined } from "@ant-design/icons";
import "./styles.css";
import { getCookie } from "typescript-cookie";
import theme from "../../styles/theme";
import login from "../../services/Auth/Login";

const Auth = (props: any) => {
    const [showProgress, setProgress] = useState("none");
    const [showError, setError] = useState("none");

    const antIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

    const onFinish = (values: any) => {
        login(
            values.email,
            values.password,
            (value: string) => {
                setError(value);
            },
            (section: string) => {
                props.setSection(section);
            }
        );
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <>
            <div id="authArea" style={{ background: theme.secondary }}>
                <Form
                    name="basic"
                    className="login-form"
                    wrapperCol={{ xs: { span: 20 }, sm: { offset: 2, span: 20 } }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="on">
                    <Form.Item name="email" rules={[{ required: true, message: "Digite seu e-mail" }]}>
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} className="textInput" placeholder="E-mail" />
                    </Form.Item>

                    <Form.Item name="password" rules={[{ required: true, message: "Digite sua senha" }]}>
                        <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} className="textInput" placeholder="Senha" />
                    </Form.Item>

                    <Form.Item wrapperCol={{ xs: { span: 8 }, sm: { offset: 8, span: 8 } }}>
                        <Button
                            type="primary"
                            style={{ cursor: "pointer", background: theme.primary }}
                            className="login-form-button loginBtn"
                            htmlType="submit">
                            Login
                        </Button>
                    </Form.Item>
                </Form>
                <Spin size="large" indicator={antIcon} style={{ display: showProgress }} />
                <div className="errorMsg" style={{ display: showError }}>
                    Usuário ou senha inválidos
                </div>
            </div>
        </>
    );
};

export default Auth;
