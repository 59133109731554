import axios from "axios";
import { getCookie } from "typescript-cookie";
import URL from "../URLs.json";

const takeMessages = async (num: Number, handleProgress: any) => {
    let url = URL.production;
    return axios.get(url.news.take + num.toString(), {
        headers: {
            Authorization: "Bearer" + getCookie("token"),
        },
    });
};

export default takeMessages;
