import React, { useEffect, useState } from "react";
import getJournals from "../../services/Journals/GetJournals";
import "./styles.css";

const BiWeeklyCard = (props : any) => {
    return (
        <div className="bwCard">
            <a className="bwLink" href={`https://news.api.sif.org.br/storage/${props.file}`} target="_blank">
                <div className="bwTitle">{props.title}</div>
            </a>
        </div>
    );
};

const Biweekly = () => {

    const [journals, setJournals] = useState([]);

    useEffect(() =>{
        getJournals().then((res) => {
            console.log(res);
            setJournals(res);
        })
    }, [])

    return (
        <div className="bwArea">
            {
                journals.map((value: any, index: any) => {
                    return <BiWeeklyCard title={value.title.split('.')[0]} file={value.file}/>
                })
            }
        </div>
    );
};

export default Biweekly;
