import axios from "axios";
import { getCookie } from "typescript-cookie";

const getSchedule = async () => {
    // const url = "https://prosif.api.user.sif.org.br/api/user";
    const url = "https://prosif.api.user.sif.org.br/api/schedule";

    try {
        const { data } = await axios.get(url, {
            headers: {
                Authorization: "Bearer " + getCookie("token"),
            },
        });

        return data;
    } catch (err) {
        // console.log(err);
    }
};

export default getSchedule;
