import React, { useState, useEffect } from "react";
import Auth from "../../components/Auth/Auth";
import { setCookie, getCookie } from "typescript-cookie";
import me from "../../services/Auth/Me";
import Messages from "../../components/Messages/Messages";

const MessagePage = () => {
    const [section, setSection] = useState(<></>);

    useEffect(() => {
        if (getCookie("token")) {
            setSection(<Messages />);
            me()
                .then((res) => {
                    const name = res.name;
                    // console.log(res);
                    setCookie("name", name);
                    // console.log(name);
                })
                .catch(() => {
                    setSection(<Auth setSection={setSection} />);
                });
        } else {
            setSection(<Auth setSection={setSection} />);
        }
    }, []);

    return <div>{section}</div>;
};

export default MessagePage;
