import React from "react";
import { Routes, Route } from "react-router-dom";
import Index from "./pages/Index/Index";
import MessagePage from "./pages/Messages/Messages";

const Router = () => {
    return (
        <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/messages" element={<MessagePage />} />
        </Routes>
    );
};

export default Router;
