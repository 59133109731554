import React, { useState, useEffect, useContext } from "react";
import Auth from "../../components/Auth/Auth";
import { setCookie, getCookie, removeCookie } from "typescript-cookie";
import Main from "../../components/Main/Main";
import me from "../../services/Auth/Me";
import NameContext from "../../contexts/NameContext";

const Index = () => {
    const [section, setSection] = useState(<></>);
    const {setName} = useContext(NameContext);

    useEffect(() => {
        if (getCookie("token")) {
            setSection(<Main />);
            me()
                .then(async (res) => {
                    const name = res.name;
                    // console.log(res);
                    await setName(name);
                    // console.log(name);
                })
                .catch(() => {
                    setSection(<Auth setSection={setSection} />);
                });
        } else {
            setSection(<Auth setSection={setSection} />);
        }
    }, []);

    return <div>{section}</div>;
};

export default Index;
