import React, {createContext} from 'react';

export type NameType = {
    name: string;
    setName: any;
}

const NameContext = createContext<NameType>({
    name: '',
    setName: () => {},
});
 
export default NameContext;

