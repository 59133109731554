import React, { useState, useEffect } from "react";
import { CloseOutlined } from "@ant-design/icons";
import theme from "../../styles/theme";
import "./styles.css";
import Modal from "antd/es/modal/Modal";
import Draggable from "react-draggable";
import Tag from "../Tag/Tag";

const getDescription = (fullDescription: string): any => {
    const description = fullDescription ? fullDescription.split(">") : "";
    return description;
};

const getColor = (tag: string): string => {
    switch (tag) {
        case "Evento":
            return theme.event;
        case "TI" || "Ti" || "ti":
            return theme.it;
        case "RH" || "Rh" || "rh":
            return theme.hr;
        case "Embrapii" || "embrapii" || "embrapi" || "Embrapi":
            return theme.embrapii;
        case "GT":
            return theme.gts;
        case "Visitas" || "visitas" || "Visita" || "visita":
            return theme.visits;
        case "Viagem":
            return theme.trips;
        case "Feriados" || "feriados" || "Feriado" || "feriado":
            return theme.holidays;
        case "Aniversario":
            return theme.birthday;
        default:
            return theme.default;
    }
};

const EventCard = (props: any) => {
    const [tag, description] = props.event ? getDescription(props.event.extendedProps.description) : ["", ""];

    const color = getColor(tag);

    return (
        <Modal
            className="eventModal"
            style={{ width: "400px", height: "250px", borderRadius: "16px" }}
            centered
            open={props.visibility}
            title={
                <div className={"eventCardHeader"} style={{ backgroundColor: color, color: color == '#FFFF44' ? 'black' : 'white' }}>
                    <h3>{tag ? props.event.title : ""}</h3>
                </div>
            }
            footer={null}
            onCancel={() => props.handleChangeVisibility(false)}
            modalRender={(modal) => (
                <Draggable>
                    <div>{modal}</div>
                </Draggable>
            )}>
            <div className="eventCardBodyMainContent">
                <span>
                    Descrição <Tag tag={tag} color={color} />
                </span>
                <div className="eventCardBodyDescription" style={{ border: "2px solid " + color }}>
                    <div>{description ? description : tag}</div>
                </div>
            </div>
            {/* <div className="eventCardBodyContent">
                <span>Convidados</span>
                <div className="eventCardBodyAttendees" style={{ border: "2px solid " + color }}></div>
            </div> */}
        </Modal>
    );
};

export default EventCard;
