import React, { useState, useEffect } from "react";
import { Select, Row, Col } from "antd";
import getSchedule from "../../services/Auth/getSchedule";
import './styles.css';

const getDay = (day: string) => {
    switch (day) {
        case 'Segunda':
            return 'segunda';
        case 'Terça':
            return 'terca';
        case 'Quarta':
            return 'quarta';
        case 'Quinta':
            return 'quinta';
        case 'Sexta':
            return 'sexta';
        default: 
            return 'none';
    }
}

const stations = 38;

const Schedule = () => {
    const [fullData, setFullData] = useState({});
    const [tableData, setTableData] = useState({});
    const [loading, setLoading] = useState(true);
    const [day, setDay] = useState(0);

    useEffect(() => {
        getSchedule().then((data) => {
            const startData = data.map((element: any) => {
                return {
                    employee: element.employee ? element.employee.name : "",
                    day: element.day,
                    station: element.station,
                    turn: element.turn,
                    key: element.id
                };
            });
            let schedule = {
                segunda: {
                    0: Array(stations),
                    1: Array(stations),
                },
                terca: {
                    0: Array(stations),
                    1: Array(stations),
                },
                quarta: {
                    0: Array(stations),
                    1: Array(stations),
                },
                quinta: {
                    0: Array(stations),
                    1: Array(stations),
                },
                sexta: {
                    0: Array(stations),
                    1: Array(stations),
                },
            }
            startData.forEach((element: any) => {
                let index = getDay(element.day);
                (schedule as any)[index][element.turn][element.station] = element.employee;
            });
            setFullData(schedule);
            setLoading(false);
        });
        
    }, []);


    useEffect(() =>{}, [loading]);

    const options = [
        {
            label: "Manhã",
            value: 0,
        },
        {
            label: "Tarde",
            value: 1,
        },
    ];

    const handleChange = (value: any) => {
        setDay(value);
    };

    console.log((fullData as any).segunda);
    return loading ? <></> : (
        <>
            <Select defaultValue="Manhã" options={options} style={{ width: "100%", marginBottom: "20px", borderRadius: "4px" }} onChange={handleChange}></Select>
            <Row justify="center" className="marginTable" style={{paddingLeft: '10px', paddingBottom: '5px', display: 'flex', alignItems: 'center', color: 'black'}}>
                <Col style={{textAlign: 'center'}} span={4}>Estação</Col>
                <Col style={{textAlign: 'center'}} span={4}>Segunda</Col>
                <Col style={{textAlign: 'center'}} span={4}>Terça</Col>
                <Col style={{textAlign: 'center'}} span={4}>Quarta</Col>
                <Col style={{textAlign: 'center'}} span={4}>Quinta</Col>
                <Col style={{textAlign: 'center'}} span={4}>Sexta</Col>
            </Row>
            <Row style={{color: 'black'}}>
                <Col span={4}>
                    {
                        Object.keys(Array(stations).fill(0)).map((key, index) => {
                            return <Row justify="center" className="marginTable" style={{paddingLeft: '10px', paddingBottom: '5px', display: 'flex', alignItems: 'center'}}>{parseInt(key) + 1}</Row>
                        })
                    }
                </Col>
                <Col span={4} style={{whiteSpace: 'nowrap'}}>
                    {
                        Object.keys((fullData as any).segunda[day]).map((key, index) => {
                            console.log(key)
                            return <Row justify="center" className="marginTable" style={{paddingLeft: '10px', paddingBottom: '5px'}}>{(fullData as any).segunda[day][key] ? (fullData as any).segunda[day][key].split(' ')[0] : '-'}</Row>
                        })
                    }
                </Col>
                <Col span={4} style={{whiteSpace: 'nowrap'}}>
                    {
                        Object.keys((fullData as any).terca[day]).map((key, index) => {
                            return <Row justify="center" className="marginTable" style={{paddingLeft: '10px', paddingBottom: '5px'}}>{(fullData as any).terca[day][key] ? (fullData as any).segunda[day][key].split(' ')[0] : '-'}</Row>
                        })
                    }
                </Col>
                <Col span={4} style={{whiteSpace: 'nowrap'}}>
                    {
                        Object.keys((fullData as any).quarta[day]).map((key, index) => {
                            return <Row justify="center" className="marginTable" style={{paddingLeft: '10px', paddingBottom: '5px'}}>{(fullData as any).quarta[day][key] ? (fullData as any).segunda[day][key].split(' ')[0] : '-'}</Row>
                        })
                    }
                </Col>
                <Col span={4} style={{whiteSpace: 'nowrap'}}>
                    {
                        Object.keys((fullData as any).quinta[day]).map((key, index) => {
                            return <Row justify="center" className="marginTable" style={{paddingLeft: '10px', paddingBottom: '5px'}}>{(fullData as any).quinta[day][key] ? (fullData as any).segunda[day][key].split(' ')[0] : '-'}</Row>
                        })
                    }
                </Col>
                <Col span={4} style={{whiteSpace: 'nowrap'}}>
                    {
                        Object.keys((fullData as any).sexta[day]).map((key, index) => {
                            return <Row justify="center" className="marginTable" style={{paddingLeft: '10px', paddingBottom: '5px'}}>{(fullData as any).sexta[day][key] ? (fullData as any).segunda[day][key].split(' ')[0] : '-'}</Row>
                        })
                    }
                </Col>
            </Row>
        </>
    );
};

export default Schedule;
