import { Layout } from "antd";
import React, { useState } from "react";
import { MenuOutlined } from "@ant-design/icons";
import Biweekly from "../Biweekly/Biweekly";
import titles from "../../styles/title.json";
import theme from "../../styles/theme";
import "./styles.css";
import NewsCalendar from "../Calendar/Calendar";
import logout from "../../services/Auth/Logout";
import Schedule from "../Schedule/Schedule";

const { Header } = Layout;

const HeaderSif = (props: any) => {
    const [select, setSelected] = useState("home");

    return (
        <Header className="header" style={{ background: theme.secondary }}>
            <div className="waves">
                <div className="headerContent">
                    <div className="dropdown">
                        <div className="headerButton">
                            <MenuOutlined style={{ color: theme.tertiary }} />
                        </div>
                        <div className="dropdownContent" style={{ backgroundColor: theme.primary }}>
                            <a
                                onClick={() => {
                                    setSelected("home");
                                    props.handlePageChange(<NewsCalendar />, titles.calendar);
                                }}
                                className="firstLink"
                                style={{ backgroundColor: select === "home" ? theme.selected : theme.primary }}>
                                Home
                            </a>
                            <a
                                onClick={() => {
                                    setSelected("quinzenais");
                                    props.handlePageChange(<Biweekly />, titles.biweekly);
                                }}
                                style={{ backgroundColor: select === "quinzenais" ? theme.selected : theme.primary }}>
                                Quinzenais
                            </a>
                            <a
                                onClick={() => {
                                    setSelected("escala");
                                    props.handlePageChange(<Schedule />, titles.schedule);
                                }}
                                style={{ backgroundColor: select === "escala" ? theme.selected : theme.primary }}>
                                Escala
                            </a>
                            <a
                                onClick={() => {
                                    logout();
                                }}
                                style={{ backgroundColor: theme.primary }}>
                                Logout
                            </a>
                        </div>
                    </div>
                    <div className="brandImage" />
                </div>
            </div>
        </Header>
    );
};

export default HeaderSif;
