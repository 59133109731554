import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import getMessages from "../../services/Message/GetMessages";
import Container from "../Container/Container";
import Pusher from "pusher-js";
import notify from "../../services/Notification/Notification";

import "./styles.css";
import Message from "../Message/Message";

const formatDate = (date: string): string => {
    const splitted = date.split("-");
    if (splitted.length === 1) {
        return date;
    } else {
        return `${splitted[2]}/${splitted[1]}/${splitted[0]}`;
    }
};

const Messages = () => {
    const [content, setContent] = useState(<div>Teste</div>);
    const [data, setData] = useState(Array());

    useEffect(() => {
        let pusher = new Pusher("bde1144948f2aed318bc", {
            cluster: "sa1",
        });
        let channel = pusher.subscribe("sif-news-development");
        channel.bind("message", (message: any) => {
            notify(message.message);
            getMessages().then((response: any) => {
                setData(response.data);
            });
        });
        getMessages().then((response: any) => {
            const newData = response.data.sort((a: any, b: any) => {
                if (new Date(a.created_at) > new Date(b.created_at)) {
                    return -1;
                }
                if (new Date(a.updated_at) < new Date(b.created_at)) {
                    return 1;
                }
                return 0;
            });
            setData(newData);
        });
    }, []);
    return (
        <>
            <Header
                handlePageChange={(newContent: JSX.Element, newTitle: string) => {
                    window.location.href = 'https://news.sif.org.br/';
                }}
            />
            <div className="index">
                <div className="contentContainer">
                    <div className="uniqueContent">
                        <Container title="Todas as mensagens">
                            {data.map((value: any) => (
                                <Message key={value.id} name={value.user_name} message={value.message} date={formatDate(value.created_at)} />
                            ))}
                        </Container>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Messages;
