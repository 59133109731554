import React, {useState, createContext} from 'react';
import NameContext from './NameContext';

type Props = {
    children: React.ReactNode;
};
  
const NameProvider : React.FC<Props> = ({children} : Props) => {
    const [name, setName] = useState('')
    return (
        <NameContext.Provider value={{name, setName}}>
            {children}
        </NameContext.Provider>
    );
};
 
export default NameProvider;    
