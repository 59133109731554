import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import ptLocale from "@fullcalendar/core/locales/pt-br";
import googleCalendarPlugin from "@fullcalendar/google-calendar";
import "./styles.css";
import EventDisplay from "../EventDisplay/EventDisplay";
import EventCard from "../EventCard/EventCard";

const NewsCalendar = () => {
    const [visibility, setVisibility] = useState(false);
    const [event, setEvent] = useState<any>("");

    return (
        <>
            <FullCalendar
                dayHeaderClassNames="dayHeader"
                initialView="dayGridMonth"
                locale={ptLocale}
                weekends={false}
                plugins={[dayGridPlugin, googleCalendarPlugin]}
                contentHeight="auto"
                googleCalendarApiKey="AIzaSyAXay4fhVf61U4avBF9k6TYAWqNT8ed6_A"
                dayMaxEventRows={3}
                events={{ googleCalendarId: "news@sif.org.br" }}
                eventContent={(info) => {
                    return <EventDisplay event={info.event} />;
                }}
                eventClick={(info) => {
                    info.jsEvent.preventDefault();
                    setVisibility(true);
                    setEvent(info.event);
                }}
            />
            <EventCard visibility={visibility} handleChangeVisibility={setVisibility} event={event} />
        </>
    );
};

export default NewsCalendar;
